<template>
  <div :class="themeClass">
    <div class="">
      <Datetime
        :type="type ? type : 'datetime'"
        class="theme-adsplayx"
        :placeholder="placeholder ? placeholder : $t('select2')"
        input-class="d-inline-block text-center form-control"
        :value="parsedDate"
        :format="format ? format : 'dd/MM/yyyy HH:mm'"
        :phrases="{ok: 'Confirmar', cancel: 'Cancelar'}"
        value-zone="America/Sao_Paulo"
        @input="emit"
        :readonly="parsedReadonly"
      />
    </div>
  </div>
</template>

<script>

import { Datetime } from 'vue-datetime'
import moment from 'moment'
// import { mapState } from 'vuex'

export default {
  name: 'DateTimeInput',
  props: [
    'theme', 'value', 'format', 'type', 'placeholder', 'readonly'
  ],
  components: {
    Datetime
  },
  data () {
    return {
      local: ''
    }
  },
  computed: {
    themeClass () {
      const theme = []
      if (this.theme) {
        theme.push(this.theme)
      }
      if (this.parsedReadonly) {
        theme.push('readonly')
      }
      return theme
    },
    parsedDate () {
      if (this.value) return moment(this.value).format()

      return ''
    },
    parsedReadonly () {
      return this.readonly === true
    }
  },
  methods: {
    emit (e) {
      this.$emit('input', e)
    }
  }
}
</script>

<style lang="css" src="vue-datetime/dist/vue-datetime.css"></style>

<style lang="scss" scoped>
.readonly {
  pointer-events: none;
}

::v-deep {

  .vdatetime-popup {
    top: calc(50% + 25px);

    @include tablet {
      top: 50%;
    }
  }

  .theme-adsplayx .vdatetime-popup__header,
  .theme-adsplayx .vdatetime-calendar__month__day--selected > span > span,
  .theme-adsplayx .vdatetime-calendar__month__day--selected:hover > span > span {
    background: $color1;
  }

  .theme-adsplayx .vdatetime-year-picker__item--selected,
  .theme-adsplayx .vdatetime-time-picker__item--selected,
  .theme-adsplayx .vdatetime-popup__actions__button,
  .theme-adsplayx .vdatetime-month-picker__item--selected {
    color: $color1;
  }
}

.basic {
  ::v-deep {
    .vdatetime-input {
      max-width: 140px;
      cursor: pointer;
      // background:transparent;
    }
  }
}
</style>
